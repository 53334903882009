import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Space, Table, message, Modal } from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Typography from "@mui/material/Typography";
import Highlighter from "react-highlight-words";
import SuspensionHistoryDialog from "./suspensionHistoryModal";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ViewTesterDetails from "../tester/viewTesterDetails";
import EditTester from "../tester/editTester";
import { ExpandAltOutlined } from "@ant-design/icons";
import AddTesterForm from "../tester/addTester";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const apiUrl = process.env.REACT_APP_API_Testers;
const suspensionHistoryApiUrl = process.env.REACT_APP_SuspensionHistory;

const Testers = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [testers, setTesters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [testerId, setTesterId] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [viewModalVisible, setviewModalVisible] = useState(false);
  const [editModalVisible, setEidtModalVisible] = useState(false);
  const [suspensionHistory, setSuspensionHistory] = useState([]);
  const [suspensionCount, setSuspensionCount] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    fetchData();
  }, [setOpen, open]);

  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);

  const formatLastLogin = (timeString) => {
    // Extract numbers from the input string
    const matches = timeString.match(/\d+/g);
    console.log("timeString", timeString);

    // if (!matches) {
    //   return "Invalid time format";
    // }
    if (timeString === "Haven't logged in yet") {
      return "Haven't logged in yet";
    }

    // Determine if days, hours, or minutes are provided
    let days = 0;
    let hours = 0;
    let minutes = 0;

    if (timeString.includes("day")) {
      days = matches[0] ? parseInt(matches[0], 10) : 0;
      if (timeString.includes("hour")) {
        hours = matches[1] ? parseInt(matches[1], 10) : 0;
      }
    } else if (timeString.includes("hour")) {
      hours = matches[0] ? parseInt(matches[0], 10) : 0;
      if (timeString.includes("minute")) {
        minutes = matches[1] ? parseInt(matches[1], 10) : 0;
      }
    } else if (timeString.includes("minute")) {
      minutes = matches[0] ? parseInt(matches[0], 10) : 0;
    }

    // Convert total hours into days
    const totalHours = hours + minutes / 60;
    days += Math.floor(totalHours / 24);
    hours = Math.floor(totalHours % 24);
    minutes = Math.floor(minutes % 60);

    // Convert days into years, months, and remaining days
    const daysInYear = 365.25;
    const daysInMonth = 30.44;

    const years = Math.floor(days / daysInYear);
    const remainingDaysAfterYears = days % daysInYear;
    const months = Math.floor(remainingDaysAfterYears / daysInMonth);
    const remainingDays = Math.floor(remainingDaysAfterYears % daysInMonth);

    let result = "";

    if (years > 0) {
      result += `${years} year${years > 1 ? "s" : ""} `;
    }

    if (months > 0) {
      result += `${months} month${months > 1 ? "s" : ""} `;
    }

    if (remainingDays > 0) {
      result += `${remainingDays} day${remainingDays > 1 ? "s" : ""} `;
    }

    if (hours > 0) {
      result += `${hours} hour${hours !== 1 ? "s" : ""} `;
    }

    if (minutes > 0) {
      result += `${minutes} minute${minutes !== 1 ? "s" : ""}`;
    }

    return result.trim() || "0 minutes";
  };

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch testers");
      }
      const data = await response.json();
      console.log("the data from the api", data);
      const formattedData = data.body.map((tester) => ({
        ...tester,
        last_login: formatLastLogin(tester.last_login),
      }));
      setTesters(formattedData);
      console.log("the formatted data", formattedData);
      setLoading(false);
      // setTesters(data.body);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching testers:", error);
    }
  };
  console.log("the ddata to be displayedd", testers);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            type="secondary"
            className="bg-[blue] text-[white]"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleViewClick = (record) => {
    setviewModalVisible(true);
    setTesterId(record.id);
  };

  const handleEditClick = (record) => {
    setEidtModalVisible(true);
    setTesterId(record.id);
  };

  const handleDeleteClick = (record) => {
    setSelectedRecord(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    // Make API call to delete the selected record
    try {
      const response = await fetch(`${apiUrl}/${selectedRecord.id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        message.success("Record deleted successfully");
        setTesters(testers.filter((record) => record.id !== selectedRecord.id));
        setLoading(false);
      } else {
        message.error("Failed to delete record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      message.error("Failed to delete record");
    }
    setDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    // Cancel delete action
    setDeleteModalVisible(false);
  };

  const handleAddTesterClick = () => {
    setOpen(true);
  };
  const handleSuspensionCountClick = async (record) => {
    try {
      const response = await fetch(`${suspensionHistoryApiUrl}/${record.id}`);
      const data = await response.json();
      const parsedData = JSON.parse(data.body);

      setSuspensionHistory(parsedData.history); // Set the history data
      setSuspensionCount(parsedData.history.length); // Set the suspension count
      setIsDialogOpen(true); // Open the dialog
    } catch (error) {
      console.error("Failed to fetch suspension history", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "12%",
      ...getColumnSearchProps("first_name"),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: "15%",
      ...getColumnSearchProps("gender"),
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Suspension Count",
      dataIndex: "suspension_count",
      key: "suspension_count",
      width: "15%",
      ...getColumnSearchProps("suspension_count"),
      sorter: (a, b) => a.suspension_count - b.suspension_count,
      sortDirections: ["descend", "ascend"],
      render: (text, record) =>
        text > 0 ? (
          <Button
            type="link"
            onClick={() => handleSuspensionCountClick(record)}
            style={{ padding: 0, textAlign: "center", width: "100%" }}
          >
            {text}
          </Button>
        ) : (
          <span
            style={{
              padding: 0,
              textAlign: "center",
              display: "inline-block",
              width: "100%",
            }}
          >
            {text}
          </span>
        ),
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      width: "15%",
      ...getColumnSearchProps("last_login"),
      sorter: (a, b) => a.last_login - b.last_login,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      dataIndex: "view",
      key: "view",
      width: "20%",
      render: (text, record) => (
        <Space>
          <Button
            icon={<ExpandAltOutlined />}
            onClick={() => handleViewClick(record)}
          >
            View Details
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteClick(record)}
            danger
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
          All Testers
        </Typography>
        <Button icon={<PlusOutlined />} onClick={handleAddTesterClick}>
          Add Tester
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={testers}
        loading={loading}
        locale={{
          emptyText: loading ? "Loading..." : "No Data",
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 5,
          pageSizeOptions: ["5", "10", "20"],
        }}
        scroll={{ x: "100%" }}
      />
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okButtonProps={{ style: { backgroundColor: "red" } }}
      >
        Are you sure you want to delete this record?
      </Modal>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        style={{ zIndex: 20 }}
      >
        <AddTesterForm setOpen={setOpen} />
      </Dialog>
      <Dialog
        fullScreen
        open={viewModalVisible}
        onClose={() => setviewModalVisible(false)}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            width: "600px",
            maxWidth: "600px",
            height: "70vh",
            maxHeight: "90vh",
            overflow: "auto",
          },
        }}
        style={{ zIndex: 20 }}
      >
        <ViewTesterDetails setOpen={setviewModalVisible} testerId={testerId} />
      </Dialog>
      <Dialog
        fullScreen
        open={editModalVisible}
        onClose={() => setEidtModalVisible(false)}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            width: "700px",
            maxWidth: "800px",
            height: "70vh",
            maxHeight: "90vh",
            overflow: "auto",
          },
        }}
        style={{ zIndex: 20 }}
      >
        <EditTester setOpen={setEidtModalVisible} id={testerId} />
      </Dialog>
      {/* Suspension History Modal */}
      <SuspensionHistoryDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        suspensionHistory={suspensionHistory}
        suspensionCount={suspensionCount}
      />
    </>
  );
};

export default Testers;
