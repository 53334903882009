import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/charts";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Row, Col } from "antd";

const DemoColumn = () => {
  const [data, setData] = useState([]);
  const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);

  const apiUrl =
    "https://jqh0cp25wl.execute-api.us-east-1.amazonaws.com/dev/all-opts-count-day-wise";
  // Function to ensure date is at midnight UTC
  const toMidnightUTC = (date) => {
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return utcDate;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedRange.length !== 2) {
        return;
      }
      try {
        const startDate = toMidnightUTC(new Date(selectedRange[0]));
        const endDate = toMidnightUTC(new Date(selectedRange[1]));

        const response = await axios.post(apiUrl, {
          start_date: startDate.toISOString().split("T")[0],
          end_date: endDate.toISOString().split("T")[0],
        });
        const optCount = response.data.opt_count;

        // Process data
        const currentWeekDates = [];
        const currentDateFormatted = new Date(startDate);
        while (currentDateFormatted <= endDate) {
          currentWeekDates.push(
            currentDateFormatted.toISOString().split("T")[0]
          );
          currentDateFormatted.setDate(currentDateFormatted.getDate() + 1);
        }

        const newData = currentWeekDates.map((date) => ({
          type: date,
          value: optCount[date] !== undefined ? optCount[date] : null,
        }));

        setData(newData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedRange, apiUrl]);

  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedRange([startDate, endDate]);
  };

  const config = {
    data,
    xField: "type",
    yField: "value",
    xAxis: {
      label: {
        formatter: (val) => {
          const date = new Date(val);
          const day = date.getDate();
          const month = date.getMonth() + 1; // Months are zero indexed, so we add 1
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
      },
    },
    style: {
      fill: ({ type }) => {
        if (type === "10-30" || type === "30") {
          return "#22CBCC";
        }
        return "#2989FF";
      },
    },
    label: {
      text: (originData) => {
        const val = parseFloat(originData.value);
        if (val < 0.05) {
          return (val * 100).toFixed(1);
        }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  return (
    <div>
      <Typography variant="h6" component="div" style={{ marginBottom: "16px" }}>
        Total OTPs generated by the special testers Team
      </Typography>
      <Row>
        <Col xs={24} md={8} className="rdrDefinedRangesWrapper">
          <div className="statistics-container">
            <DateRangePicker
              onChange={handleDateChange}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: selectedRange[0],
                  endDate: selectedRange[1],
                  key: "selection",
                },
              ]}
              className="custom-date-range-picker"
            />
          </div>
        </Col>

        <Col xs={24} md={{ span: 10, offset: 6 }}>
          <Column {...config} />
        </Col>
      </Row>
    </div>
  );
};

export default DemoColumn;
